import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'

import SEO from '../../../components/atoms/Seo'
import media from '../../../utils/media'
import Navbar from '../../../components/organisms/Navbar'
import StripeTable from '../../../components/molecules/StripeTable'
import HomeSectionTitle from '../../../components/atoms/HomeSectionTitle'
import useWindowPathname from '../../../utils/useWindowPathname'
import getSearchFromLocation from '../../../utils/params'
import { useFetchMeQuery } from '../../../queries/users'

const MarginContainer = styled.div`
  margin-top: 52px;
  text-align: center;

  ${media.lessThan('md')`
    margin-top: 52px;
  `}
`

const OffresAbonnementVente = ({ location }) => {
  const title = 'Nos différentes offres d\'abonnement pour la vente'
  const description = 'Nos différentes offres d\'abonnement pour déposer une annonce pour la vente de local professionnel'
  const params = getSearchFromLocation()

  const returnUrl = useWindowPathname()
  const { data, error, isFetching } = useFetchMeQuery(true, returnUrl)
  const reference = get(data, 'user.id', '')

  return (
    <>
      <SEO
        title={title}
        description={description}>
      </SEO>
      <Navbar location={location} />
      <MarginContainer>
        <HomeSectionTitle>Déposer une annonce</HomeSectionTitle>
      </MarginContainer>
      {isEmpty(error) && !isFetching && reference &&
        <StripeTable>
          <stripe-pricing-table
            publishable-key="pk_live_51NqYCvAZuoCRZZBsPc3XZdkKRz9XrKN2xqX8LJiP3NShS8f7Urm4yETpI9bLna9TFKLBUNi4oPwRD11NV2Fhm18m00nM4oVbJO"
            pricing-table-id="prctbl_1P3Ci3AZuoCRZZBswxwkHitz"
            client-reference-id={`user${reference}-ad${get(params, 'ad', '')}`}>
          </stripe-pricing-table>
        </StripeTable>
      }
    </>
  )
}

OffresAbonnementVente.propTypes = {
  location: PropTypes.object.isRequired
}

export default OffresAbonnementVente
